<template lang="pug">
  .app-container.permissions-list
    .filter-container
      //- el-button(v-waves :loading="downloading" class="filter-item" type="primary" icon="el-icon-download" @click="handleDownload") Экспорт
      el-input(v-model="query.keyword" placeholder="Имя" style="width: 200px;" class="filter-item" clearable @keyup.enter.native="handleFilter")
      el-select(v-model="query.role" placeholder="Роль" clearable style="width: 200px" class="filter-item" @change="handleFilter")
        el-option(v-for="item in roles" :key="item.id" :label="item.name | uppercaseFirst" :value="item.id")
      el-button(v-waves class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter") Найти
      el-button(class="filter-item" style="margin-left: 10px;" type="primary" icon="el-icon-plus" @click="handleCreate") Создать

    el-table(v-loading="loading" :data="list" border fit highlight-current-row style="width: 100%")
      el-table-column(align="center" label="ID" width="80")
        template(slot-scope="scope")
          span {{ scope.row.id }}

      el-table-column(align="center" label="Имя")
        template(slot-scope="scope")
          span {{ scope.row.name }}

      el-table-column(align="center" label="Роли")
        template(slot-scope="scope")
          span {{ scope.row.roles.join(', ') }}

      el-table-column(align="center" label="Действия" width="400")
        template(slot-scope="scope")
          el-button(type="primary" size="small" icon="el-icon-edit" v-permission="['manage admin__permissions']" @click="handleEdit(scope.row.id)") Изменить
          el-button(type="info" size="small" icon="el-icon-copy-document" v-permission="['manage admin__permissions']" @click="handleDuplicate(scope.row.id)") Копировать
          //- v-if="!scope.row.roles.includes('admin')"
          //- el-button.permissions-list__action(type="warning" size="small" icon="el-icon-edit" v-permission="['manage permission']" @click="handleEditPermissions(scope.row.id)") Роли
          //- v-if="scope.row.roles.includes('visitor')"
          el-button.permissions-list__action(type="danger" size="small" icon="el-icon-delete" v-permission="['manage admin__permissions']"  @click="handleDelete(scope.row.id, scope.row.name)") Удалить

    pagination(v-show="total>0" :total="total" :page.sync="query.page" :limit.sync="query.limit" @pagination="getList")

    el-dialog.permissions-list__permissions-dialog(:visible.sync="dialogEditVisible" :title="'Изменить разрешение - ' + currentPermission.name")
      .form-container(v-loading="dialogEditLoading" v-if="currentPermission.name")
        el-form(ref="editForm" :rules="rules" :model="currentPermission" label-position="left" label-width="150px" style="max-width: 500px;")
          el-form-item(label="Имя" prop="name")
            el-input(v-model="currentPermission.name")

          el-form-item(label="Роли" prop="roles")
            el-checkbox-group.permissions-list__checkbox-group(v-model="currentPermission.roles")
              el-checkbox(v-for="item in roles" :key="item.name" :label="item.name") {{ item.name | uppercaseFirst }}

        div(style="text-align:right;")
          el-button(type="danger" @click="dialogEditVisible=false") Отменить

          el-button(type="primary" @click="confirmEdit") Сохранить

    el-dialog(:title="'Создать разрешение'" :visible.sync="dialogCreateVisible")
      .form-container(v-loading="dialogCreateLoading")
        el-form(ref="createForm" :rules="rules" :model="newPermission" label-position="left" label-width="150px" style="max-width: 500px;")
          el-form-item(label="Имя" prop="name")
            el-input(v-model="newPermission.name")

          el-form-item(label="Роли" prop="roles")
            el-checkbox-group.permissions-list__checkbox-group(v-model="newPermission.roles")
              el-checkbox(v-for="item in roles" :key="item.name" :label="item.name") {{ item.name | uppercaseFirst }}


        .dialog-footer(slot="footer")
          el-button(@click="dialogCreateVisible = false") Отменить

          el-button(type="primary" @click="confirmCreate") Сохранить

</template>
<script>
  import Pagination from '@/components/Pagination' // Secondary package based on el-pagination
  import UserResource from '@/api/user'
  import Resource from '@/api/resource'
  import waves from '@/directive/waves' // Waves directive
  import permission from '@/directive/permission' // Waves directive
  import checkPermission from '@/utils/permission' // Permission checking
  import UserPermissions from '@/views/users/UserPermissions'
  import { catchRequest, notifyRequest } from '@/utils/api'

  const permissionResource = new Resource('permissions')


  export default {
    name: 'PermissionList',
    components: { Pagination, UserPermissions },
    directives: { waves, permission },
    data() {
      return {
        list: null,
        total: 0,
        loading: true,
        downloading: false,
        permissionCreating: false,
        query: {
          page: 1,
          limit: 15,
          keyword: '',
          role: '',
        },
        roles: this.$store.state.user.allRoles,
        newPermission: {},
        dialogCreateLoading: false,
        dialogCreateVisible: false,
        dialogEditVisible: false,
        dialogEditLoading: false,
        currentPermissionId: 0,
        currentPermission: {
          id: 0,
          name: '',
          roles: [],
        },
        rules: {
          name: [{ required: true, message: 'Имя - обязательное поля', trigger: 'blur' }]
        },
        permissionProps: {
          children: 'children',
          label: 'name',
          disabled: 'disabled',
        },
        permissions: [],
        rolePermissions: [],
      }
    },
    computed: {
    },
    created() {
      this.resetNewPermission()
      this.getList()
      // if (checkPermission(['manage permission'])) {
      //   this.getPermissions();
      // }
    },
    methods: {
      checkPermission,

      async getList() {
        const { limit, page } = this.query
        this.loading = true
        const { data, meta } = await permissionResource.list(this.query)
        this.list = data
        this.list.forEach((element, index) => {
          element['index'] = (page - 1) * limit + index + 1
        })
        this.roles = this.$store.state.user.allRoles
        this.total = meta.total
        this.loading = false
      },
      handleFilter() {
        this.query.page = 1
        this.getList()
      },
      reload() {
        if (this.total === this.query.limit * (this.query.page - 1)) {
          this.query.page--
        }
        this.getList()
      },
      handleCreate() {
        this.resetNewPermission()
        this.dialogCreateVisible = true
        this.$nextTick(() => {
          this.$refs['createForm'].clearValidate()
        })
      },
      handleDelete(id, name) {
        this.$confirm('Данное действие навсегда удаляет разрешение ' + name + '. Продолжить?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }).then(() => {
          permissionResource.destroy(id).then(response => {
            this.$message({
              type: 'success',
              message: 'Удаление успешно',
            })
            this.reload()
          }).catch(error => {
            console.log(error)
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: 'Удаление отменено',
          })
        })
      },
      async handleEdit(id) {
        this.currentPermissionId = id
        this.dialogEditLoading = true
        this.dialogEditVisible = true
        const found = this.list.find(permission => permission.id === id)
        this.currentPermission = found
        // this.currentPermission = {
        //   name: found.name,
        //   roles: found.roles
        // };
        // const { data } = await permissionResource.get(id);
        // this.permissions = data;
        this.dialogEditLoading = false
        this.$nextTick(() => {
          // this.$refs.menuPermissions.setCheckedKeys(this.permissionKeys(this.userMenuPermissions));
          // this.$refs.otherPermissions.setCheckedKeys(this.permissionKeys(this.userOtherPermissions));
        })
      },
      handleDuplicate(id) {
        let item = this.list.filter(el => {
          return el.id === id
        })
        if (!item || !item.length) {
          return
        }
        item = item[0]
        this.$confirm(`Копировать "${item.name}" (ID: ${item.id})?`, 'Подтвердите копирование', {
          confirmButtonText: 'Подтвердить',
          cancelButtonText: 'Отменить',
          // type: 'warning'
        }).then(() => {
          this.duplicateItem(id)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: 'Копирование отменено'
          })
        })
      },
      async duplicateItem(id) {
        const response = await notifyRequest({
          url: `/permissions/${id}/duplicate`,
          method: 'post'
        }, 'Разрешение успешно скопировано')
        this.reload()
        return response
      },
      resetNewPermission() {
        this.newPermission = {
          name: '',
          roles: [],
        }
      },
      handleDownload() {
        this.downloading = true
        /* import('@/vendor/Export2Excel').then(excel => {
          const tHeader = ['id', 'user_id', 'name', 'email', 'role'];
          const filterVal = ['index', 'id', 'name', 'email', 'role'];
          const data = this.formatJson(filterVal, this.list);
          excel.export_json_to_excel({
            header: tHeader,
            data,
            filename: 'user-list',
          });
          this.downloading = false;
        });*/
      },
      formatJson(filterVal, jsonData) {
        return jsonData.map(v => filterVal.map(j => v[j]))
      },

      confirmEdit() {
        this.dialogEditLoading = true

        permissionResource.update(this.currentPermissionId, this.currentPermission).then(response => {
          this.$message({
            message: 'Разрешение успешно обновлено',
            type: 'success',
            duration: 5 * 1000,
          })
          this.dialogEditLoading = false
          this.dialogEditVisible = false
          // this.getList();
        }).catch(error => {
          this.dialogEditLoading = false
        })
      },

      confirmCreate() {
        this.dialogCreateLoading = true

        permissionResource.store(this.newPermission).then(response => {
          this.$message({
            message: 'Разрешение успешно создано',
            type: 'success',
            duration: 5 * 1000,
          })
          this.dialogCreateLoading = false
          this.dialogCreateVisible = false
          this.getList()
        }).catch(error => {
          this.dialogCreateLoading = false
        })
      }
    },
  }
</script>

<style lang="sass">
  @import "@/assets/sass/blocks/permissions-list.sass"
</style>
